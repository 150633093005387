import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
Welcome to Blue Book Security
      <h4>A Security Site for Small Businesses</h4>
    </Text>
    <hr></hr>
    <p>{`Small-business owners just want to get a basic understanding of a broad and confusing area.  This site is one way to achieve that - by way of blogs and other postings.`}</p>
    <p>{`I attempt to guide all kinds of small businesses:`}</p>
    <blockquote>
      <p parentName="blockquote">{`law firms`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`dental offices`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`medical practices`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`small manufacturers`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`et cetera, et cetera`}</p>
    </blockquote>
    <p>{`Contact me if you're interested.`}</p>
    <h4>{`Need More ?`}</h4>
    <p>{`If you want more help, I'll provide some basic training to your staff.`}</p>
    <h4>{`Still More ?`}</h4>
    <p>{`Finally, I can connect you with providers:`}</p>
    <blockquote>
      <p parentName="blockquote">{`IT companies`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`equipment providers`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`security blue teams`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`response teams`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`... whatever`}</p>
    </blockquote>
    <p>{`I earn nothing from these third parties. `}<strong parentName="p">{`You`}</strong>{` are my only client.`}</p>
    <p>{`I also write a bit.  Enjoy the postings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      